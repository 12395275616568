import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["radioButton"];

  togglePhoneSelection(event) {
    this.radioButtonTargets.forEach(radio => {
      radio.checked = false;
      radio.value = false;
    });

    event.target.checked = true;
    event.target.value = true;
  }

  deletePhone(event) {
    event.preventDefault();
    const dataFieldId = $(event.currentTarget).data('phone-id');

    if (dataFieldId) {
      const destroyField = $(event.currentTarget).prev();
      destroyField[0].value = "true";
      // Hide the removed field from UI.
      $(event.currentTarget).closest('.phone-fields').addClass('d-none');
    } else {
      $(event.currentTarget).closest('.phone-fields').remove();
    }
  }
}
