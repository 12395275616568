import { Turbo } from "@hotwired/turbo-rails";
import checkPlaceOfInterest from "../helpers/checkPlaceOfInterest";
import BaseController from "./controller";

export default class extends BaseController {
  connect() {
  }

  constructionReportSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);

    fetch(e.target.action, {
      method: 'POST',
      body: formData
    })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = 'report.xlsx'; // Set desired filename
      downloadLink.click();
    })
    .catch(error => console.error('Error:', error));
  }
}
