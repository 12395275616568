import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ["additionalFilters", "icon"];

  toggle() {
    const blueBar = document.querySelector('.blue-bar');
    this.additionalFiltersTargets.forEach((element) => {
      if (element.classList.contains('show')) {
        // Set current height before collapsing for a smooth transition
        element.style.maxHeight = element.scrollHeight + "px";
        element.offsetHeight; //Force reflow
        element.style.transition = "max-height 0.6s ease, visibility 0s 0.6s";
        element.classList.remove('show');
        setTimeout(() => {
          element.style.maxHeight = "0px"; // Collapse smoothly
        }, 20) //Small delay to ensure transition is applied
      } else {
        element.classList.add('show');
        element.style.transition = "max-height 0.6s ease, visibility 0s 0.2s";
        setTimeout(() => {
          element.style.maxHeight = element.scrollHeight + "px"; // Expand smoothly
        }, 20);
      }
    });

    const openElements = this.additionalFiltersTargets.filter(el => el.classList.contains('show'));
    let newHeight = 399 + openElements.reduce((acc, el) => acc + el.scrollHeight, 0);

    const maxHeight = 600;
    newHeight = Math.min(newHeight, maxHeight);
    
    blueBar.style.transition = "height 0.6s ease";
    blueBar.offsetHeight; //Force reflow
    blueBar.style.height = `${newHeight}px`;

    // Toggles icon rotation
    this.iconTarget.classList.toggle("rotated");
  }
}
