import { Controller } from "@hotwired/stimulus"
import { start } from "@popperjs/core";

export default class extends Controller {
  static targets = [
    "startCamera",
    "sendPhoto",
    "takePhoto",
    "liveVideo",
    "photoImg",
    "cancelPhoto",
    "switchCamera",
    "flexSwitchCheckDefault"
  ]

  stream = null;

  connect() {
    if (this.isIOS()) {
      this.startCameraTarget.style.display = 'block';
      this.startCameraTarget.addEventListener('click', function() {
        this.camera();
      }.bind(this));
    } else {
      this.camera();
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.geolocationSuccess.bind(this), this.geolocationError.bind(this));
    } else {
      console.error("Unable to retrieve your location.");
    }
  }

  geolocationSuccess(position) {
    this.latitude = position.coords.latitude;
    this.longitude = position.coords.longitude;

    this.sendPhotoTarget.disabled = false;
  }

  geolocationError() {
    console.error("Unable to retrieve your location.");
  }

  async camera() {
    const faceDiv = document.querySelector('#faceDiv');
    const photoDiv = document.querySelector('#photoDiv');

    const isMobile = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    let isFrontCamera = true;

    if (isMobile()) {
      this.switchCameraTarget.style.display = 'block';
    }

    // const cameraPermission = await navigator.permissions.query({ name: 'camera' });
    // if (cameraPermission.state !== 'granted') {
    //   document.getElementById('permission').style.display = 'block';
    //   return;
    // }

    this.switchCameraTarget.addEventListener('click', async function (e) {
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
      }

      isFrontCamera = !isFrontCamera;

      this.liveVideoTarget.style.transform = isFrontCamera ? 'scaleX(-1)' : 'scaleX(1)';

      const constraints = {
        audio: false,
        video: {
          facingMode: isFrontCamera ? 'user' : 'environment'
        }
      };

      try {
        this.stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.liveVideoTarget.srcObject = this.stream;
      } catch (error) {
        console.error("Erro ao acessar a câmera: ", error);
        // Aqui, você pode informar o usuário que não foi possível alternar a câmera
      }
    }.bind(this));


    this.stream = await navigator.mediaDevices.getUserMedia({ video: true });
    this.liveVideoTarget.srcObject = this.stream;

    const capturePhoto = () => {
      const canvas = document.createElement('canvas');
      canvas.width = this.liveVideoTarget.videoWidth;
      canvas.height = this.liveVideoTarget.videoHeight;

      const context = canvas.getContext('2d');
      context.drawImage(this.liveVideoTarget, 0, 0, canvas.width, canvas.height);

      const photoURL = canvas.toDataURL('image/png');
      this.photoImgTarget.src = photoURL;
      this.photoImgTarget.width = canvas.width;
      this.photoImgTarget.height = canvas.height;
    }

    this.takePhotoTarget.addEventListener('click', function(e) {
      e.preventDefault();

      faceDiv.style.display = 'none';
      photoDiv.style.display = 'flex';

      capturePhoto();
    });

    this.cancelPhotoTarget.addEventListener('click', function(e) {
      e.preventDefault();
      faceDiv.style.display = 'flex';
      photoDiv.style.display = 'none';
    });

    this.sendPhotoTarget.addEventListener('click', (e) => {
      this.sendPhotoTarget.disabled = true;
      const photoURL = this.photoImgTarget.src;

      const isChecked = this.flexSwitchCheckDefaultTarget.checked;

      fetch('/punches/process_photo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
          image_data: photoURL,
          latitude: this.latitude,
          longitude: this.longitude,
          type: isChecked
        })
      })
      .then(response => {
        if (response.redirected) {
          this.liveVideoTarget.srcObject.getTracks().forEach(track => track.stop());
          this.liveVideoTarget.srcObject = null;
          window.location.href = response.url;
        } else {
          response.json().then(data => {
            if (data.error) {
              const flashMessage = document.createElement('div');
              flashMessage.classList.add('alert', 'alert-danger', 'alert-dismissible', 'fade', 'show');
              flashMessage.textContent = data.error;

              const closeButton = document.createElement('button');
              closeButton.type = 'button';
              closeButton.classList.add('btn-close');
              closeButton.setAttribute('data-bs-dismiss', 'alert');
              closeButton.setAttribute('aria-label', 'Close');

              flashMessage.appendChild(closeButton);
              document.querySelector('#flash-messages').appendChild(flashMessage);

              this.sendPhotoTarget.disabled = false;

              faceDiv.style.display = 'flex';
              photoDiv.style.display = 'none';
            } else {
              // Handle other types of responses
            }
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    });
  };

  isIOS() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (iosPlatforms.includes(platform)) {
       return true;
    }

    return /iPad|iPhone|iPod/.test(userAgent) ||
           (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  }

  disconnect() {
    if (this.stream) {
      const tracks = this.stream.getTracks();
      tracks.forEach(track => track.stop());
    }
  }
};
