import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="table-component"
export default class extends Controller {
  static targets = ["selectAll", "rowCheckbox"];

  connect() {
    if (this.hasSelectAllTarget) {
      this.selectAllTarget.addEventListener(
        "change",
        this.toggleAll.bind(this)
      );
    }
    this.setupClickableRows();
  }

  toggleAll() {
    const isChecked = this.selectAllTarget.checked;
    this.rowCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
  }

  setupClickableRows() {
    const rows = this.element.querySelectorAll("tr[data-href]");
    rows.forEach((row) => {
      row.addEventListener("click", (event) => {
        if (event.target.closest("a, button, input, select, textarea")) {
          return;
        }

        window.location = row.getAttribute("data-href");
      });
    });
  }
}
